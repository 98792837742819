// Import only the data source we need
import * as faycalData from "./faycal.json";
import * as sanaeData from "./sanae.json";
import sanaePic from "../assets/sanae.webp";
import faycalPic from "../assets/faycal.webp";

// Define the dataToUse variable
const dataToUse = "faycal";

// Function to determine the data and profile picture to export
function getProfileData(dataSource: string) {
	if (dataSource === "faycal") {
		return { data: faycalData, profilPic: faycalPic };
	}
	return { data: sanaeData, profilPic: sanaePic };
}

// Destructure the result based on dataToUse
const { data, profilPic } = getProfileData(dataToUse);

export { data, profilPic };
