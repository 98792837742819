import styled from "styled-components";
import { data } from "../data/source";
import IconTitle from "../components/IconTitle";
import { FaLaptop } from "react-icons/fa";
import { PortfolioItem } from "../Interfaces/PortfolioInterface";

const PortfolioComponent: React.FC = () => {
	return (
		<Fragment>
			<div className="bar">
				<IconTitle title="PORTFOLIO" iconComponent={FaLaptop} size={24} />
			</div>
			<hr className="light" />
			<PortfolioWrapper>
				<div className="portfolio-items">
					{(data.portfolio as PortfolioItem[]).map((item, index) => (
						<div className="portfolio-item" key={index}>
							<h3 className="item-title">
								<a
									href={item.url}
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: item.description ? "var(--color-main)" : "var(--color-text)" }}
								>
									{item.title}
								</a>
							</h3>
							{item.description && <p className="description">{item.description}</p>}
						</div>
					))}
				</div>
			</PortfolioWrapper>
		</Fragment>
	);
};

export default PortfolioComponent;

const PortfolioWrapper = styled.div`
	padding: var(--spacing-medium);
	padding-left: 0;
	padding-right: 0;
	margin: 0 auto;

	.bar {
		display: flex;
	}

	.titles {
		font-size: 2.5vw;
		height: fit-content;
		letter-spacing: 0.2vw;
		margin: auto 0 0 1.5vw;
	}

	.lines {
		height: 1px;
		background-color: #575959;
		margin-top: 1vw;
	}

	.portfolio-items {
		display: flex;
		flex-wrap: wrap;
		gap: var(--spacing-medium);
		justify-content: space-between;
	}

	.portfolio-item {
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
		border: 1px solid var(--color-light-border);
		border-radius: var(--border-radius);
		flex: 1 1 calc(33.333% - var(--spacing-medium));
		padding: var(--spacing-medium);
		transition: transform 0.3s ease, box-shadow 0.3s ease;

		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
		}
	}

	.item-title {
		font-size: var(--font-size-task);
		margin: 0;
		a {
			/* color: var(--color-main); */
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.description {
		font-size: var(--font-size-p);
		color: var(--color-text);
	}

	@media (max-width: 768px) {
		.titles {
			font-size: 4vw;
		}

		.portfolio-item {
			flex: 1 1 calc(50% - var(--spacing-medium));
		}

		.description {
			font-size: var(--font-size-small);
		}
	}
`;

const Fragment = styled.div`
	height: fit-content;
	margin-top: 2vw;
`;
