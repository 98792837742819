import React from "react";
import styled from "styled-components";
import IconTitle from "../components/IconTitle";
import { FaBriefcase } from "react-icons/fa";
import { data } from "../data/source";
import { ExperienceInterface } from "../Interfaces/ExperienceInterface";

// Utility function to calculate duration
const calculateDuration = (startDate: string, endDate: string): string => {
	const start = new Date(startDate);
	const end = endDate.toLowerCase() === "present" ? new Date() : new Date(endDate);
	const years = end.getFullYear() - start.getFullYear();
	const months = end.getMonth() - start.getMonth() + years * 12;
	const yearLabel = years === 1 ? "year" : "years";
	const monthLabel = months === 1 ? "month" : "months";
	return years > 0
		? `(${years} ${yearLabel}${months % 12 > 0 ? `, ${months % 12} ${monthLabel}` : ""})`
		: months > 0
		? `(${months} ${monthLabel})`
		: "";
};

const ExperienceComponent: React.FC = () => {
	return (
		<StyledExperienceComponent>
			<div className="bar">
				<IconTitle title="EXPERIENCE" iconComponent={FaBriefcase} size={24} />
			</div>
			<hr className="light" />
			{(data.experience as ExperienceInterface[]).map((item, i) => (
				<div className="contents" key={`experience${i}`}>
					<div className="space1">
						<div className="date-duration-company">
							<p className="date">
								{item.date}
								<span className="duration">{calculateDuration(item.startDate, item.endDate)}</span>
							</p>
							<p className="company">{item.company}</p>
						</div>
						<div className="techContainer">
							{item.technologies?.map((tech, j) => (
								<p className="tech" key={`tech${j}`}>
									{tech}
								</p>
							))}
						</div>
					</div>
					<div className="space2">
						<p className="task">{item.task}</p>
						<p className="brief">{item.brief}</p>
					</div>
					{i < (data.experience as ExperienceInterface[]).length - 1 && <hr className="mobile-divider" />}
				</div>
			))}
		</StyledExperienceComponent>
	);
};

export default ExperienceComponent;

const StyledExperienceComponent = styled.div`
	.titles {
		font-size: var(--font-size-h2);
		height: fit-content;
		letter-spacing: var(--letter-spacing-medium);
		margin: auto 0 0 var(--spacing-medium);
	}

	.contents {
		display: flex;
		margin-top: var(--spacing-large);
		flex-direction: column;
		gap: var(--spacing-small);
	}

	.space1 {
		width: 100%;
		height: fit-content;
		display: flex;
		flex-direction: column;
		gap: var(--spacing-small);
	}

	.date-duration-company {
		display: flex;
		justify-content: flex-start;
		align-items: start;
		flex-direction: column;
		.duration {
			margin: 0;
			margin-left: var(--spacing-xsmall);
			font-size: var(--font-size-xsmall);
		}
	}

	.date {
		font-size: var(--font-size-date);
		font-family: var(--font-family-secondary);
		margin: 0;
	}

	.duration {
		color: var(--color-grey);
		margin: 0;
	}

	.company {
		margin: 0;
		color: var(--color-main);
	}

	.techContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: start;
		gap: var(--spacing-xxsmall);
		margin: 0 var(--spacing-xsmall) var(--spacing-xsmall) 0;
	}

	.tech {
		color: var(--color-grey);
		border: var(--border-dashed);
		margin: 0;
		padding: 0 var(--spacing-xsmall);
		display: inline-block;
	}

	.space2 {
		width: 100%;
		margin-left: 0;
		height: fit-content;
	}

	.task {
		margin: 0;
		font-size: var(--font-size-task);
		text-transform: uppercase;
	}

	.brief {
		font-family: var(--font-family-secondary);
		text-align: justify;
		margin: 0;
		margin-top: var(--spacing-small);
	}

	.mobile-divider {
		display: block;
		width: 100%;
		border: none;
		border-top: var(--border-light);
		margin: var(--spacing-medium) 0;
	}

	@media (max-width: 767px) {
		.contents * {
			width: 100%;
		}
		.date {
			font-size: var(--font-size-medium);
		}

		.company {
			font-size: var(--font-size-medium);
		}

		.tech {
			font-size: var(--font-size-small);
			width: fit-content;
		}

		.brief {
			font-size: var(--font-size-small);
		}
		.date-duration-company {
			justify-content: space-between;
			align-items: center;
			.duration {
				font-size: var(--font-size-small);
			}
		}
	}

	@media (min-width: 768px) {
		.contents {
			flex-direction: row;
		}
		.date-company {
			display: flex;
			flex-direction: column;
		}

		.space1 {
			width: 27%;
		}

		.space2 {
			flex: 1;
			margin-left: var(--spacing-medium);
		}

		.date {
			font-size: var(--font-size-medium);
		}

		.company {
			font-size: var(--font-size-small);
		}

		.tech {
			font-size: var(--font-size-small);
		}

		.brief {
			font-size: var(--font-size-large);
		}

		.mobile-divider {
			display: none;
		}
	}
`;
