import styled from "styled-components";

export const ParagraphTag = styled.p`
	display: block;
	color: var(--color-white);
	font-size: var(--font-size-h1);
	margin: 0 auto;
	width: fit-content;
`;

export const Profile = styled.img`
	display: block;
	width: 15rem;
	height: 15rem;
	object-fit: cover;
	border-radius: 50%;
	margin: 0 auto;
	transition: all 500ms ease;
	&:hover {
		filter: brightness(1.2);
		width: 28vw;
		height: 28vw;
		cursor: none;
	}
	@media (max-width: 768px) {
		width: 10rem;
		height: 10rem;
		&.header-pic {
			width: 4rem;
			height: 4rem;
			margin: 0;
			margin-right: 0;
		}
		&:hover {
			filter: brightness(1);
			transform: scale(1);
			cursor: none;
		}
	}
`;
export const FullName = styled(ParagraphTag)`
	font-size: 1.6rem;
	text-align: center;
	@media (max-width: 768px) {
		font-size: 1.2rem;
	}
`;
export const Profession = styled(ParagraphTag)`
	font-size: 1.4vw;
	text-align: center;
	color: var(--color-main);
	text-transform: uppercase;

	@media (max-width: 768px) {
		font-size: 1rem;
	}
`;
export const AboutIntro = styled(ParagraphTag)`
	font-size: 1.1rem;
	font-family: "nunito", sans-serif;
	text-align: center;
	color: var(--color-white);
	word-spacing: 0.4rem;
	max-height: 40vh;
	overflow: auto;
	@media (max-width: 768px) {
		font-size: 1.2rem;
	}
`;
