import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { data, profilPic } from "../data/source";
import { AboutMeInterface } from "../Interfaces/AboutMeInterface";
import { AboutIntro, FullName, Profession, Profile } from "./Misc";
import ContactRows from "./ContactRows";
import { FaChevronDown } from "react-icons/fa";

interface MobileHeaderProps {
	className?: string;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ className }) => {
	const aboutData = data.about as AboutMeInterface;
	const [isAtTop, setIsAtTop] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			setIsAtTop(window.scrollY < 50);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const handleScrollClick = () => {
		window.scrollTo({
			top: window.innerHeight,
			behavior: "smooth",
		});
	};

	return (
		<MobileHeaderContainer className={`mobile-only ${className || ""}`}>
			<Profile alt="profilePic" src={profilPic} />
			<div>
				<FullName>{aboutData.fullname}</FullName>
				<Profession>{aboutData.profession}</Profession>
			</div>
			<hr />
			<AboutIntro>{aboutData.aboutme}</AboutIntro>
			<hr />
			<ContactRows />
			{isAtTop && (
				<ScrollIndicator onClick={handleScrollClick}>
					<FaChevronDown />
				</ScrollIndicator>
			)}
		</MobileHeaderContainer>
	);
};

export default MobileHeader;

const MobileHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: var(--spacing-medium);
	gap: var(--spacing-medium);
	background-color: var(--color-background);
	width: 100%;
	box-sizing: border-box;
	position: relative;

	hr {
		width: 100%;
		border: none;
		border-top: var(--border-light);
	}

	@media (min-width: 768px) {
		display: none;
	}
`;

const ScrollIndicator = styled.button`
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	background: var(--color-main);
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	animation: bounce 2s infinite;
	z-index: 100;

	svg {
		color: white;
		font-size: 20px;
	}

	@keyframes bounce {
		0%,
		20%,
		50%,
		80%,
		100% {
			transform: translateX(-50%) translateY(0);
		}
		40% {
			transform: translateX(-50%) translateY(-10px);
		}
		60% {
			transform: translateX(-50%) translateY(-5px);
		}
	}
`;
