import React from "react";
import styled from "styled-components";
import { data } from "../data/source";
import { AboutMeInterface } from "../Interfaces/AboutMeInterface";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaLinkedin } from "react-icons/fa";

const ContactRows: React.FC = () => {
	const contactData = (data.about as AboutMeInterface).contact;

	return (
		<StyledContactRows>
			<div className="contact-row">
				<FaMapMarkerAlt className="icon" />
				<p>{contactData.address}</p>
			</div>
			<div className="contact-row">
				<FaPhoneAlt className="icon" />
				<div>
					{contactData.phone.map((phone, index) => (
						<a key={index} href={`tel:${phone}`}>
							<p>{phone}</p>
						</a>
					))}
				</div>
			</div>
			<div className="contact-row">
				<FaEnvelope className="icon" />
				<div>
					{contactData.mail.map((mail, index) => (
						<a key={index} href={`mailto:${mail}`}>
							<p>{mail}</p>
						</a>
					))}
				</div>
			</div>
			<div className="contact-row">
				<FaLinkedin className="icon" />
				<a href={contactData.linkedIn.link} target="_blank" rel="noopener noreferrer">
					{contactData.linkedIn.title}
				</a>
			</div>
		</StyledContactRows>
	);
};

export default ContactRows;

const StyledContactRows = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	.contact-row {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		a,
		p {
			text-decoration: none;
			color: var(--color-white);
			&:hover,
			&:focus,
			&:active {
				color: var(--color-white);
			}
			font-size: var(--font-size-medium);
			margin: 0;
		}
		* {
			color: var(--color-white);
		}
	}
`;
