import React from "react";
import styled from "styled-components";
import { data } from "../data/source";
import IconTitle from "../components/IconTitle";
import { FaTools } from "react-icons/fa";
import { ProSkillsInterface } from "../Interfaces/ProSkillsInterface";

const ProSkillsComponent: React.FC = () => {
	return (
		<StyledProSkillsComponent className="fragment">
			<div className="bar">
				<IconTitle title="SKILLS" iconComponent={FaTools} />
			</div>
			<hr className="light" />
			<div className="charts-container">
				{(data.skills as ProSkillsInterface[]).map((skill, i) => (
					<div className="space" key={`skill${i}`}>
						<p className="skill">{skill.name}</p>
						<div className="chart">
							<div className="chart-fill" style={{ width: `${skill.percentage}%` }} />
						</div>
					</div>
				))}
			</div>
		</StyledProSkillsComponent>
	);
};

export default ProSkillsComponent;

const StyledProSkillsComponent = styled.div`
	.fragment {
		height: fit-content;
		margin-bottom: var(--spacing-large);
	}

	.bar {
		display: flex;
	}

	.lines {
		height: 1px;
		background-color: #575959;
		margin-top: 1vw;
	}

	.space {
		margin-top: 1vw;
	}

	.skill {
		font-size: 1.1vw;
		margin: 0;
	}

	.charts-container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 15px;
		padding-top: var(--spacing-medium);
		width: 100%;
	}

	.chart {
		width: 100%;
		height: 0.5vw;
		background-color: var(--color-chart-bg);
		border-radius: 0.3vw;
	}

	.chart-fill {
		height: 100%;
		background-color: var(--color-main);
		border-radius: 0.3vw;
	}

	/* Responsive styles */
	@media (max-width: 768px) {
		.skill {
			font-size: 3vw;
		}

		.charts-container {
			grid-template-columns: 1fr;
			grid-gap: 10px;
		}

		.chart {
			height: 1vw;
		}
	}

	@media (max-width: 480px) {
		.skill {
			font-size: 4vw;
		}

		.charts-container {
			grid-gap: 5px;
		}

		.chart {
			height: 2vw;
		}
	}
`;
