import React from "react";
import styled from "styled-components";
import IconTitle from "../components/IconTitle";
import { FaGraduationCap } from "react-icons/fa";
import { data } from "../data/source";
import { EducationInterface } from "../Interfaces/EducationInterface";

const EducationComponent: React.FC = () => {
	return (
		<StyledEducationComponent>
			<div className="bar">
				<IconTitle title="EDUCATION" iconComponent={FaGraduationCap} size={24} />
			</div>
			<hr className="light" />
			{(data.education as EducationInterface[]).map((item, i) => (
				<div className="contents" key={`education${i}`}>
					<div className="space1">
						<div className="date-duration-institution">
							<p className="date">{item.date}</p>
							<p className="institution">{item.university}</p>
						</div>
					</div>
					<div className="space2">
						<p className="task">{item.grade}</p>
						<p className="brief">{item.brief}</p>
					</div>
					{i < (data.education as EducationInterface[]).length - 1 && <hr className="mobile-divider" />}
				</div>
			))}
		</StyledEducationComponent>
	);
};

export default EducationComponent;

const StyledEducationComponent = styled.div`
	.titles {
		font-size: var(--font-size-h2);
		height: fit-content;
		letter-spacing: var(--letter-spacing-medium);
		margin: auto 0 0 var(--spacing-medium);
	}

	.contents {
		display: flex;
		margin-top: var(--spacing-large);
		flex-direction: column;
		gap: var(--spacing-small);
	}

	.space1 {
		width: 100%;
		height: fit-content;
		display: flex;
		flex-direction: column;
		gap: var(--spacing-small);
	}

	.date-duration-institution {
		display: flex;
		justify-content: flex-start;
		align-items: start;
		flex-direction: column;
	}

	.date {
		font-size: var(--font-size-date);
		font-family: var(--font-family-secondary);
		margin: 0;
	}

	.institution {
		margin: 0;
		color: var(--color-main);
	}

	.space2 {
		width: 100%;
		margin-left: 0;
		height: fit-content;
	}

	.task {
		margin: 0;
		font-size: var(--font-size-task);
		text-transform: uppercase;
	}

	.brief {
		font-family: var(--font-family-secondary);
		text-align: justify;
		margin: 0;
		margin-top: var(--spacing-small);
	}

	.mobile-divider {
		display: block;
		width: 100%;
		border: none;
		border-top: var(--border-light);
		margin: var(--spacing-medium) 0;
	}

	@media (max-width: 767px) {
		.contents * {
			width: 100%;
		}
		.date {
			font-size: var(--font-size-medium);
		}

		.institution {
			font-size: var(--font-size-medium);
		}

		.brief {
			font-size: var(--font-size-small);
		}
		.date-duration-institution {
			justify-content: space-between;
			align-items: center;
		}
	}

	@media (min-width: 768px) {
		.contents {
			flex-direction: row;
		}
		.date-institution {
			display: flex;
			flex-direction: column;
		}

		.space1 {
			width: 27%;
		}

		.space2 {
			flex: 1;
			margin-left: var(--spacing-medium);
		}

		.date {
			font-size: var(--font-size-medium);
		}

		.institution {
			font-size: var(--font-size-small);
		}

		.brief {
			font-size: var(--font-size-large);
		}

		.mobile-divider {
			display: none;
		}
	}
`;
