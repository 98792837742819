import styled from "styled-components";
import { data, profilPic } from "../data/source";
import ContactRows from "../components/ContactRows";
import { AboutIntro, FullName, Profession, Profile } from "../components/Misc";
import { AboutMeInterface } from "../Interfaces/AboutMeInterface";

const AboutMeComponent: React.FC = () => {
	const aboutData = data.about as AboutMeInterface;

	return (
		<AboutContainer className="desktop-only">
			<Profile alt="profilePic" src={profilPic} />
			<div>
				<FullName>{aboutData.fullname}</FullName>
				<Profession>{aboutData.profession}</Profession>
			</div>
			<hr />
			<AboutIntro>{aboutData.aboutme}</AboutIntro>
			<hr />
			<ContactRows />
		</AboutContainer>
	);
};
export default AboutMeComponent;

const AboutContainer = styled.div`
	position: fixed;
	width: 30vw;
	top: 0;
	left: 0;
	bottom: 0;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: var(--color-background);
	padding: var(--spacing-medium) 0;
	padding: 1rem;
	flex-direction: column;
	box-sizing: border-box;
	gap: 1rem;
`;
